<template>
    <div style="position:relative;" class="pre-start-checklist">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <div>
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Pre Start Checklist for Plant and Equipment</h1>
                    <p>Construction and heavy civil sites involve heavy machinery and equipment, which can be dangerous if not used correctly. Especially, workplace accidents can lead to significant consequences, including injuries, operational delays, and financial setbacks. However, regular inspection of the plant and equipment before use ensures that it is in good working condition.By performing a pre start check, we can identify any issues early on, preventing breakdowns and unplanned downtime, which improves overall project efficiency and reduces delays. A pre start checklist ensures that all safety features are checked, and any potential hazards or malfunctions are identified before the machinery or equipment is operated. With the help of Record TIME, users can perform comprehensive pre start inspections for different types of equipment. As a result, it will enhance workplace safety and promote a healthier work environment.</p>
                
                    <h3>See How Pre Start Inspection Works for Your Business</h3><br/>
           <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                @click="redirectToLink('https://recordtime.com.au/blog/pre-start-checklist-template')" class="mr-7 btn-1" elevation="0">
                Know More
            </v-btn>
            <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                Get A Demo Now
            </v-btn>
            </div>
            </div>
            <div  class="right-banner">
                <img :src="`${publicPath}assets/features-image/pre-stat-checklist-for-equipment.webp`" alt="pre start checklist for the safety inspection of plants and equipment" style="max-width: 100%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        <v-container><br/><br/>
            <h2>Crafting Your Custom Pre Start Checklist with Record TIME's Docket Templates</h2>
            <h3>Pre Start Inspections Made Easy: Unveiling Record TIME's Drag and Drop Technique</h3><br/>
            <p>Say goodbye to time-consuming paperwork and tedious manual modifications. With Record TIME's pre start checklist feature, you can create comprehensive checklists tailored to your specific needs. Additionally, it has an extensive collection of pre-designed checklist templates that are easy to customise through drag and drop options. To ensure a reliable record, Record TIME enables users to add electronic signatures and instant photos to each checklist item. This function not only confirms the identity of the person who was responsible for the inspection but also provides a visual record for future reference.</p>
            <img :src="`${publicPath}assets/features-image/pre-start-checklist-approved.webp`" alt="create your pre start checklist with drag and drop technique" style="margin-top: 20px; object-fit: contain;width: 100%;">
            <br/><br/><br/>
            <h2 class="customH2">Safety Benefits of Pre Start Checklist</h2>
            <p>The inspection checklist helps to identify any dangers or hazards associated with the equipment before it is used. This proactive approach allows for timely maintenance or repairs, reducing the risk of accidents or injuries.</p>
             <br/><br/>
            <h2 class="customH2">Financial Benefits of Pre Start Checklist </h2><br/>
            <p>The checklist is a systematic roadmap that helps identify issues or bottlenecks early on, leading to a quick resolution and preventing costly delays. Furthermore, the pre start inspection contributes to cost savings, improved customer satisfaction, and increased corporate profitability by minimising errors, avoiding equipment breakdowns, and optimising resources.</p>
       <br/><br/>
       <h2>Enhance Workplace Health: Plant and Equipment Safety</h2>
            <p>With the innovative inclusion of the Record TIME platform, users can seamlessly perform vehicle safety checks. These include forklifts, vehicles, excavators, ewp, and truck. The platform offers a dedicated template for forklift pre start checklist, vehicle pre start checklist, <a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist">excavator pre start checklist</a>, ewp pre start checklist, and truck pre start checklist. Importantly, all these templates align with industry best practices.
                <br/><br/>The availability of the docket templates ensures that every aspect of safety is meticulously examined. This, in turn, promotes a secure working environment and significantly reduces the risk of accidents. By employing the provided plant pre start checklist template and leveraging the capabilities of Record TIME, construction professionals can consistently uphold workplace safety standards. Ultimately, this fosters a healthier and more secure atmosphere for all individuals involved in the construction processes.
            </p>
            </v-container>
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>
    </div>
</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 60%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 40%; 
        float: right;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .pre-start-checklist{
        .banner-img{

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .customH2{
        position: relative;
        display: inline-block;
        padding-right: 55px;
        margin-bottom: 15px;
        color: #00C8D8;
        img{
            position: absolute;
            right: 0px;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 500px);
      padding-right: 15px;
    }
    .right-blog1{
      width: 500px;
      float:right;
      img{
        max-width: 500px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
    title: 'Pre Start Checklist | Ensuring Safety, Efficiency, and Compliance',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Boost construction safety with Record TIMEs pre start checklist. Easy templates, identify potential hazards, and promote timely maintenance.'},
      {name: 'keyword', content: 'pre start checklist'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/pre-start-checklist' }
    
    ]
  },
    components: {
     GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
            window.open(url, '_blank');
        },
        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
